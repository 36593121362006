<template>
    <div class="page-digital-comms-templates container">
        <div class="row">
            <div class="col-12">
                <h1 class="heading">Digital Comms Templates</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <app-table
                    class="templates-table"

                    :cols="cols"
                    :rows="templates"

                    title="Templates"

                    :sort-value="filter.sort"
                    :sort-options="sort"
                    @sort-change="onSortChange"

                    :loading="loading"

                    :clickable="true"
                    @row-click="goToTemplateDetails"
                    @row-click-mobile="goToTemplateDetails"
                />

                <app-pagination
                    v-if="pagination.total"

                    v-model="pagination.page"

                    :total="pagination.total"
                    :limit="pagination.limit"
                    :page-range="pagination.range"

                    @change="onPageChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

import formatDateNano from '@/helpers/format-date-nano'

export default {
    components: {
        appTable,
        appPagination,
    },
    
    data() {
        return {
            templates: [],

            filter: {
                sort: {
                    field: 'TemplateID',
                    order: 'asc'
                },
            },

            sort: [
                { field: 'TemplateID',     title: 'Template ID' },
                { field: 'Description',    title: 'Description' },
                { field: 'updated',        title: 'Updated at'  },
            ],

            cols: [
                { key: 'TemplateID',     title: 'Template ID', sort: { field: 'TemplateID'     } },
                { key: 'Description',    title: 'Description', sort: { field: 'Description'    } },
                { key: 'updated',        title: 'Updated At',  sort: { field: 'updated'        } },
            ],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            loading: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getDigitalCommsTemplates()
        },

        getDigitalCommsTemplates(page) {
            this.loading = true

            const filter = {
                ...this.filter,
            }

            const params = {
                'SPID':                     this.current_spid,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
                'SearchOptions.SortBy':     filter.sort.field,
                'SearchOptions.SortDesc':   filter.sort.order === 'desc' ? 1 : 0,
            }

            this.$store.dispatch('api_notification/FindNotificationTemplatesPaginated', params).then(({ PageInfo, NotificationTemplates }) => {
                this.templates = NotificationTemplates.map(template => ({
                    uuid: template.UUID,
                    updated: template.UpdatedAtNanos ? formatDateNano(template.UpdatedAtNanos) : '?',
                    ...template,
                }))

                const { DisplayPageList, PageNumber, TotalItemCount } = PageInfo

                this.pagination.page = PageNumber
                this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                if (this.$route.params.page != this.pagination.page
                    && !(
                        this.$route.params.page === undefined
                        && this.pagination.page === 1
                    )
                ) {
                    this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                }

                this.loading = false
            }).catch(error => {
                console.log(error)

                this.templates = []

                this.loading = false
            })
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getDigitalCommsTemplates()
        },

        onPageChange(page) {
            this.getDigitalCommsTemplates(page)
        },

        goToTemplateDetails(template) {
            this.$router.push({ name: 'digital-comms-template', params: { uuid: template.uuid } })
        },
    },
    computed: {
        ...mapGetters([
            'current_spid',
        ]),
    }
}
</script>

<style lang="scss">
.page-digital-comms-templates {
    padding-bottom: 80px;
    
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .templates-table {
        @include table-cols-width((250px, 270px, 150px), true)
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-digital-comms-templates {
        .templates-table {
            @include table-cols-width((240px, 250px, 115px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-digital-comms-templates {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .templates-table {
            margin-bottom: 15px;

            @include table-cols-width-mobile((80px, 165px, 24px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>